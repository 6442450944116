<template>
    <el-dialog
        title="添加跟进记录"
        :visible.sync="isShow"
        width="480px"
        :append-to-body="true"
        :before-close="onClose"
        @open="onOpen"
    >
        <el-form v-if="formData" :model="formData" :rules="rules" ref="formData" label-width="110px">
            <el-form-item label="联系方式" prop="contact">
                <el-select v-model="formData.contact" placeholder="请选择">
                    <el-option v-for="item in contactList" :key="item.id" :label="item.contact" :value="item.contact" />
                </el-select>
            </el-form-item>
            <el-form-item label="跟进方式" prop="followType">
                <el-select v-model="formData.followType" placeholder="请选择">
                    <el-option
                        v-for="item in followRecordTypeList"
                        :key="item.val"
                        :label="item.name"
                        :value="item.val"
                    />
                </el-select>
            </el-form-item>
            <el-form-item v-if="formData.followType === 'tel'" label="发送短信" prop="smsFlag">
                <el-switch active-text="已发送" v-model="formData.smsFlag" />
            </el-form-item>
            <el-form-item label="时间" prop="followTime">
                <el-date-picker
                    :disabled="true"
                    v-model="formData.followTime"
                    type="datetime"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    placeholder="请选择日期"
                />
            </el-form-item>
            <el-form-item label="内容" prop="followContent">
                <el-input
                    type="textarea"
                    :autosize="{ minRows: 3, maxRows: 4 }"
                    :maxlength="128"
                    show-word-limit
                    placeholder="请输入"
                    v-model="formData.followContent"
                />
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="onClose">取 消</el-button>
            <el-button type="primary" @click="onSubmitForm">确 定</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { followRecordTypeList, contactType, followRecordType } from '@/js/config/opportunit.js'
import { oppoFollowRecordAdd, oppoContactList } from '@/js/api/saleApi.js'
import dayjs from 'dayjs'
import { mapGetters } from 'vuex'

export default {
    props: ['isShow', 'activeItem'],
    computed: { ...mapGetters(['userInfo']) },
    data() {
        return {
            contactList: [],
            contactType,
            followRecordTypeList,
            followRecordType,
            formData: null,
            rules: {
                contact: [{ required: true, message: '请选择', trigger: 'change' }],
                followType: [{ required: true, message: '请选择', trigger: 'change' }],
                smsFlag: [{ required: true, message: '请选择', trigger: 'change' }],
                followTime: [{ required: true, message: '请选择', trigger: 'change' }],
                followContent: [{ required: true, message: '请输入', trigger: 'change' }],
            },
        }
    },
    methods: {
        onOpen() {
            let formData = {
                contact: '',
                followType: '',
                smsFlag: false,
                followTime: dayjs().format('YYYY-MM-DD HH:mm:ss'),
                followContent: '',
            }
            this.formData = formData

            this.oppoContactListApi()
        },
        onClose() {
            this.formData = null
            this.$emit('onClose')
        },
        onSubmitForm() {
            this.$refs.formData.validate((valid) => {
                if (valid) {
                    const { contact, followType, followTime, followContent, smsFlag } = this.formData
                    const params = {
                        oppoId: this.activeItem.id,
                        projectNo: this.activeItem.projectNo,
                        followType,
                        followTime,
                        smsFlag,
                        followContent,
                        contact,
                        optId: this.userInfo.id,
                        staffId: this.userInfo.id,
                    }
                    this.contactList.forEach((item) => {
                        if (contact === item.contact) {
                            params.contactType = item.contactType
                        }
                    })
                    oppoFollowRecordAdd(params).then(() => {
                        this.$message.success('添加成功')
                        this.onClose()
                        this.$emit('onClose', true)
                    })
                }
            })
        },
        // 获取联系方式
        oppoContactListApi() {
            oppoContactList({
                oppoId: this.activeItem.id,
                projectNo: this.activeItem.projectNo,
            }).then((res) => {
                this.contactList = res.data
            })
        },
    },
}
</script>
<style lang="less" scoped>
.el-form {
    padding-right: 28px;
    .el-date-editor,
    .el-textarea,
    .el-select {
        width: 100%;
    }
}
</style>
