<template>
    <div class="user_follow">
        <div>
            <el-button type="primary" :disabled="!editState" @click="onShowEditFollowRecord(null)" plain>
                添加记录
            </el-button>
        </div>
        <br />
        <el-timeline :reverse="true">
            <el-timeline-item v-for="item in recordList" :key="item.id" :timestamp="item.followTime" placement="top">
                <el-card>
                    <div class="contact_contaner">
                        <Tag color="#2874B2" v-if="item.smsFlag">已发送短信</Tag>
                        <span class="contact">{{ item.contact }}</span>
                        <span class="staff"> 操作人: {{ item.staffName }} </span>
                    </div>
                    <div class="record_content" v-if="item.followContent">{{ item.followContent }}</div>
                </el-card>
            </el-timeline-item>
        </el-timeline>

        <EditFollowRecord
            @onClose="onCloseEditFollowRecord"
            :isShow.sync="isShowEditFollowRecord"
            :activeItem="activeItem"
        />
    </div>
</template>
<script>
import EditFollowRecord from './EditFollowRecord.vue'
import { oppoFollowRecordList } from '@/js/api/saleApi.js'
import { followRecordType, callStatusType } from '@/js/config/opportunit.js'
import { getStaffList } from '@/js/api/staffApi'

export default {
    props: ['activeItem', 'editState'],
    components: { EditFollowRecord },
    data() {
        return {
            callStatusType,
            followRecordType,
            recordList: [],

            isShowEditFollowRecord: false,
        }
    },
    created() {
        this.oppoFollowRecordListApi()
    },
    methods: {
        onShowEditFollowRecord() {
            this.isShowEditFollowRecord = true
        },
        onCloseEditFollowRecord(state) {
            this.isShowEditFollowRecord = false
            if (state) this.oppoFollowRecordListApi()
        },

        async oppoFollowRecordListApi() {
            const res1 = await oppoFollowRecordList({
                projectNo: this.activeItem.projectNo,
                oppoId: this.activeItem.id,
            })
            if (res1.data.length !== 0) {
                const _staffIds = []
                res1.data.forEach((item) => {
                    if (!_staffIds.includes(item.staffId)) {
                        _staffIds.push(item.staffId)
                    }
                })
                const res2 = await getStaffList({ staffIds: _staffIds })
                const _map = {}
                res2.data.forEach((staff) => {
                    _map[staff.id] = staff
                })
                res1.data.forEach((i) => {
                    i.staffName = _map[i.staffId] ? _map[i.staffId]['name'] : '-'
                })
            }

            this.recordList = res1.data
        },
    },
}
</script>
<style lang="less" scoped>
.el-card {
    border-radius: 15px;
}
.contact_contaner {
    display: flex;
    align-items: center;
    .contact {
        color: #000;
        margin: 0 24px 0 16px;
    }
    .staff {
        margin-right: 8px;
        font-size: 12px;
        color: #666;
    }
}
.record_content {
    margin-top: 18px;
    line-height: 1.4em;
}
</style>
