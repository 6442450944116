<template>
    <div class="user_info">
        <div class="title">基本信息</div>
        <div class="intents row">
            <span class="label">意向:</span>
            <span class="value" v-if="activeItem.intents && activeItem.intents.length !== 0">
                <template v-for="intent in activeItem.intents">
                    <Tag
                        v-if="intentTypeMap[intent]"
                        :key="`intents${intent}`"
                        :color="intentTypeMap[intent]['color']"
                        style="margin: 0 6px 4px 0"
                    >
                        {{ intentTypeMap[intent]['name'] }}
                    </Tag>
                    <Tag :key="`intents${intent}`" v-else color="#aaaaaa">未知</Tag>
                </template>
            </span>
            <span class="value" v-else>-</span>
        </div>
        <div class="row">
            <span class="label">备注:</span>
            <span class="value">
                <el-input
                    :disabled="!editState"
                    :maxlength="32"
                    style="width: 90%"
                    show-word-limit
                    @change="onUsernameChange"
                    placeholder="请输入"
                    v-model="alias"
                />
            </span>
        </div>
        <div class="account row">
            <span class="label">账号:</span>
            <span class="value" v-if="accountList.length !== 0">
                <div class="account_item" v-for="item in accountList" :key="item.unionId">
                    <el-avatar :size="36" shape="square" :src="item.avatar" />
                    <div>
                        <div>
                            <span>{{ item.nickname }} </span>
                            <span> 学员ID: {{ item.userId }}</span>
                        </div>
                        <div>{{ item.addTime }}</div>
                    </div>
                </div>
            </span>
            <span class="value" v-else>-</span>
        </div>
    </div>
</template>
<script>
import { oppoMod } from '@/js/api/saleApi.js'
import { mapGetters } from 'vuex'

export default {
    props: ['activeItem', 'intents', 'editState', 'accountList', 'intentTypeMap'],
    computed: { ...mapGetters(['userInfo']) },

    data() {
        return {
            alias: '',
        }
    },
    created() {
        this.alias = this.activeItem.alias
    },
    methods: {
        onUsernameChange() {
            const params = { subId: this.activeItem.subId, alias: this.alias }
            oppoMod(params).then(() => {
                this.$message({ message: '修改成功', type: 'success', duration: 1000 })
                this.$emit('onUpdate')
            })
        },
    },
}
</script>
<style lang="less" scoped>
.user_info {
    width: 350px;
    flex-shrink: 0;
}
.user_name {
    display: flex;
    align-items: center;
    > div {
        display: flex;
        align-items: center;
    }

    .oppo_id {
        margin-right: 40px;
    }
}
.account {
    .value {
        display: flex;
        flex-wrap: wrap;
    }
    .account_item {
        display: flex;
        margin: 0 12px 6px 0;
        .el-avatar {
            flex-shrink: 0;
        }
        > div {
            flex-grow: 1;
            margin-left: 12px;
            font-size: 12px;
            line-height: 18px;
            > div:nth-child(2) {
                color: #999999;
            }
        }
    }
}

.row {
    display: flex;
    margin-top: 12px;
}

.label {
    color: #666;
    line-height: 32px;
    flex-shrink: 0;
}
.value {
    margin: 0 16px;
    flex-grow: 1;
}
</style>
