<template>
    <div>
        <el-table :data="tableData" border stripe>
            <el-table-column align="center" prop="commitTime" label="表单时间" width="150" />
            <el-table-column header-align="center" prop="providerName" label="投放渠道" width="120" />
            <el-table-column header-align="center" prop="telNo" label="联系方式">
                <template slot-scope="scope">
                    <div>
                        <span>{{ contactType[scope.row.contactType] }}:</span>
                        <span>{{ scope.row.contact }}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column header-align="center" label="表单详情" min-width="100px">
                <template slot-scope="scope">
                    <div v-if="scope.row.formDetailListMap">
                        <div class="form_detail" v-for="(item, index) in scope.row.formDetailListMap" :key="index">
                            {{ item.name }}: <span>{{ item.value }}</span>
                        </div>
                    </div>
                    <div v-else-if="scope.row.consultContent">
                        {{ scope.row.consultContent }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column header-align="center" label="快递信息" min-width="200">
                <template slot-scope="scope">
                    <div class="express_info" v-if="scope.row.expressInfo">
                        <div>
                            姓名: <span>{{ scope.row.expressInfo.receiveName }}</span>
                        </div>
                        <div>
                            手机号: <span>{{ scope.row.expressInfo.receiveMobile }}</span>
                        </div>
                        <div>
                            收货地址: <span>{{ scope.row.expressInfo.receiveAddress }}</span>
                        </div>
                        <div>
                            快递公司: <span>{{ scope.row.expressInfo.companyName }}</span>
                        </div>
                        <div>
                            快递单号: <span>{{ scope.row.expressInfo.expressNo }}</span>
                        </div>
                    </div>
                    <span v-else>-</span>
                </template>
            </el-table-column>

            <el-table-column align="center" label="操作" width="120">
                <template slot-scope="scope">
                    <el-link
                        @click="onShowEditExpress(scope.row)"
                        v-if="scope.row.orderNo && !scope.row.expressInfo"
                        type="primary"
                    >
                        编辑收货信息
                    </el-link>
                    <span v-else>-</span>
                </template>
            </el-table-column>
        </el-table>

        <EditExpress :isShow="isShowEditExpress" :activeForm="activeForm" @onClose="onCloseEditExpress" />
    </div>
</template>
<script>
import EditExpress from './tel/EditExpress.vue'
import { xbProviderList, getFormExpressList } from '@/js/api/advertApi.js'
import { trackFormRecord } from '@/js/api/saleApi.js'
import { contactType } from '@/js/config/opportunit.js'

export default {
    props: ['activeItem'],
    components: { EditExpress },
    data() {
        return {
            contactType,

            tableData: [],

            activeForm: '',
            isShowEditExpress: false,
            expressData: [],
        }
    },
    created() {
        this.initData()
    },
    methods: {
        // 编辑快递信息
        onShowEditExpress(item) {
            this.activeForm = item
            this.isShowEditExpress = true
        },
        onCloseEditExpress(state) {
            this.activeForm = ''
            this.isShowEditExpress = false
            if (state) this.initData()
        },

        async initData() {
            const res1 = await trackFormRecord({
                oppoId: this.activeItem.id,
                projectNo: this.activeItem.projectNo,
            })
            if (res1.data && res1.data.length !== 0) {
                const _providerNos = []
                const _orderNos = []
                res1.data.forEach((i) => {
                    if (!_providerNos.includes(i.providerNo)) {
                        _providerNos.push(i.providerNo)
                    }
                    if (!_orderNos.includes(i.orderNo)) {
                        _orderNos.push(i.orderNo)
                    }
                    if (i.formDetail) {
                        i.formDetailListMap = JSON.parse(i.formDetail)
                    }
                })
                const res2 = await Promise.all([
                    xbProviderList({ providerNos: _providerNos }),
                    getFormExpressList({ orderNos: _orderNos }),
                ])
                const _providerMap = {}
                res2[0].data.forEach((i) => {
                    _providerMap[i.providerNo] = i.name
                })
                const _expressMap = {}
                res2[1].data.forEach((i) => {
                    _expressMap[i.orderNo] = i
                })

                res1.data.forEach((i) => {
                    i.expressInfo = _expressMap[i.orderNo]
                    i.providerName = _providerMap[i.providerNo]
                })
            }
            this.tableData = res1.data || []
        },
    },
}
</script>
<style lang="less" scoped>
.form_detail,
.express_info {
    span {
        font-weight: 600;
        color: #000000;
    }
}
</style>
