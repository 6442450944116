<template>
    <el-drawer class="userInfo_drawer" :visible.sync="isShow" size="75%" :before-close="onClose">
        <div v-if="activeItem" class="detail">
            <UserInfo
                :accountList="accountList"
                :activeItem="activeItem"
                :editState="editState"
                :intentTypeMap="intentTypeMap"
                @onUpdate="onUpdate"
            />
            <UserTel :activeItem="activeItem" :editState="editState" @onUpdate="onUpdate" />
            <UserWechat :activeItem="activeItem" :editState="editState" @onUpdate="onUpdate" />
        </div>
        <div class="pp_container" v-if="activeItem">
            <div class="pp_content">
                <div class="index">
                    序号 <span>{{ activeItem.oppoIndex }}</span>
                </div>
                <el-button :disabled="activeItem.oppoIndex === 1" @click="onPageDown('prev')" type="text">
                    上一个
                </el-button>
                <el-button :disabled="activeItem.lastOppo" @click="onPageDown('next')" type="text">下一个</el-button>
            </div>
        </div>
        <el-tabs v-model="activeTab">
            <el-tab-pane v-for="tab in tabList" :key="tab.val" :label="tab.name" :name="tab.val" />
        </el-tabs>
        <template v-if="activeItem">
            <UserForm :activeItem="activeItem" v-if="activeTab === 'form'" />
            <UserFollow :activeItem="activeItem" :editState="editState" v-if="activeTab === 'follow'" />
            <UserCourse
                :activeItem="activeItem"
                :userId="accountList.length !== 0 ? accountList[0]['userId'] : ''"
                v-if="activeTab === 'course'"
            />
            <UserOrder :activeItem="activeItem" v-if="activeTab === 'order'" />
        </template>
    </el-drawer>
</template>
<script>
import UserInfo from './UserInfo.vue'
import UserWechat from './UserWechat.vue'
import UserTel from './UserTel.vue'
import UserForm from '../UserForm.vue'
import UserCourse from '../UserCourse.vue'
import UserFollow from '../UserFollow.vue'
import UserOrder from '../UserOrder.vue'
import { oppoUserWechat } from '@/js/api/saleApi.js'
import { mapGetters } from 'vuex'

export default {
    props: ['isShow', 'activeItem', 'intentTypeMap'],
    components: { UserInfo, UserForm, UserCourse, UserFollow, UserOrder, UserWechat, UserTel },
    computed: {
        ...mapGetters(['userInfo']),
        editState: function () {
            return this.activeItem && this.userInfo.id === this.activeItem.staffId
        },
    },
    watch: {
        activeItem: function () {
            if (this.activeItem) {
                this.onOpened()
            }
        },
    },
    data() {
        return {
            activeTab: 'follow',
            tabList: [
                { name: '表单记录', val: 'form' },
                { name: '跟进记录', val: 'follow' },
                { name: '公开课', val: 'course' },
                { name: '订单', val: 'order' },
            ],
            accountList: [],
        }
    },
    methods: {
        onOpened() {
            this.activeTab = 'follow'
            this.hasUpdate = false
            this.accountList = []
            this.oppoUserWechatApi()
        },
        onClose() {
            this.accountList = []
            this.$emit('onClose', this.hasUpdate)
        },

        onPageDown(type) {
            this.$emit('pageDown', type)
        },

        onUpdate() {
            this.hasUpdate = true
        },
        oppoUserWechatApi() {
            oppoUserWechat({
                oppoId: this.activeItem.id,
                projectNo: this.activeItem.projectNo,
            }).then((res) => {
                this.accountList = res.data
            })
        },
    },
}
</script>
<style lang="less" scoped>
.userInfo_drawer {
    /deep/ .el-drawer {
        min-width: 1100px;
    }
    /deep/ .el-drawer__header {
        height: 0;
        margin-bottom: 12px;
    }
    .detail {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
    }
}
/deep/ .title {
    font-size: 14px;
    font-weight: 700;
    line-height: 2em;
    height: 2em;
}

.pp_container {
    position: relative;
    z-index: 2;
    .pp_content {
        position: absolute;
        right: 20px;
        top: 0;
        display: flex;
        align-items: center;
    }
    .index {
        margin-right: 12px;
        color: #444;
        span {
            color: #000;
            font-weight: 600;
        }
    }
}
</style>
