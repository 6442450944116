<template>
    <el-dialog
        title="编辑快递信息"
        :append-to-body="true"
        :visible.sync="isShow"
        width="480px"
        :before-close="onClose"
        @opened="onOpened"
    >
        <el-form v-if="formData" :model="formData" :rules="rules" ref="formData" label-width="100px">
            <el-form-item label="订单号" prop="orderNo">
                <el-input v-model.trim="formData.orderNo" disabled />
            </el-form-item>
            <el-form-item label="姓名" prop="receiveName">
                <el-input
                    v-model.trim="formData.receiveName"
                    placeholder="请输入收货人姓名"
                    :maxlength="32"
                    show-word-limit
                />
            </el-form-item>
            <el-form-item label="手机号" prop="receiveMobile">
                <el-input
                    v-model.trim="formData.receiveMobile"
                    placeholder="请输入收货人手机号"
                    :maxlength="11"
                    show-word-limit
                />
            </el-form-item>
            <el-form-item label="收货地址" prop="receiveAddress">
                <el-input
                    v-model.trim="formData.receiveAddress"
                    placeholder="请输入收货地址"
                    :maxlength="64"
                    show-word-limit
                />
            </el-form-item>
            <!-- <el-form-item label="快递公司" prop="companyName">
                <el-input
                    v-model.trim="formData.companyName"
                    placeholder="请输入快递公司"
                    :maxlength="32"
                    show-word-limit
                />
            </el-form-item>
            <el-form-item label="快递单号" prop="expressNo">
                <el-input
                    v-model.trim="formData.expressNo"
                    placeholder="请输入快递单号"
                    :maxlength="64"
                    show-word-limit
                />
            </el-form-item>
            <el-form-item label="发货时间" prop="expressNo">
                <el-date-picker
                    v-model="formData.sentTime"
                    style="width: 100%"
                    type="datetime"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    placeholder="请选择日期（未填写时，默认当前时间）"
                    :clearable="false"
                />
            </el-form-item> -->
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="onClose">取 消</el-button>
            <el-button type="primary" @click="onSubmit">确 定</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { saveFormExpress } from '@/js/api/advertApi.js'
import { filterQueryEmpty } from '@/js/utils.js'

export default {
    props: ['isShow', 'activeForm'],
    data() {
        return {
            formData: null,
            rules: {
                orderNo: [{ required: true, message: '请输入', trigger: 'change' }],
                receiveName: [{ required: true, message: '请输入', trigger: 'change' }],
                receiveMobile: [{ required: true, message: '请输入', trigger: 'change' }],
                receiveAddress: [{ required: true, message: '请输入', trigger: 'change' }],
            },
        }
    },
    methods: {
        onOpened() {
            let formData = {
                orderNo: this.activeForm.orderNo,
                receiveName: '',
                receiveMobile: '',
                receiveAddress: '',
                companyName: '',
                expressNo: '',
                sentTime: '',
            }
            if (this.activeForm.contactType === 'tel') {
                formData.receiveMobile = this.activeForm.contact
            }
            if (this.activeForm.formDetailListMap) {
                this.activeForm.formDetailListMap.forEach((i) => {
                    if (i.name === '收货地址') {
                        const _v = i.value ? i.value.split(',') : []
                        formData.receiveName = _v[0] || ''
                        formData.receiveAddress = _v[2] || ''
                    }
                })
            }

            this.formData = formData
        },
        onClose() {
            this.formData = null
            this.$emit('onClose')
        },
        onSubmit() {
            this.$refs.formData.validate((valid) => {
                if (valid) {
                    let params = { ...this.formData }
                    params = filterQueryEmpty(params)
                    saveFormExpress(params).then(() => {
                        this.$message.success('编辑成功')
                        this.onClose()
                        this.$emit('onClose', true)
                    })
                }
            })
        },
    },
}
</script>
