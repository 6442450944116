import { hunter_post, hunter_post_form, cupid_post_form } from '../http'

export const xbProviderList = (data) => {
    return hunter_post({ path: '/xbProvider/list', data })
}

export const xbProviderDaySummaryList = (data) => {
    return hunter_post({ path: '/xbProviderCost/list', data })
}
export const xbProviderDaySummaryAdd = (data) => {
    return hunter_post({ path: '/xbProviderCost/add', data, isCatch: true })
}
export const xbProviderDaySummaryMod = (data) => {
    return hunter_post({ path: '/xbProviderCost/mod', data })
}
export const xbProviderCostPut = (data) => {
    return hunter_post({ path: '/xbProviderCost/put', data, isCatch: true })
}

export const xbTrackFormRecordList = (data) => {
    return hunter_post({ path: '/xbTrackFormRecord/list', data })
}

export const getSearchWordList = (data) => {
    return hunter_post({ path: '/xbProvider/searchWord/list', data })
}

export const xbProviderSave = (data) => {
    return hunter_post({ path: '/xbProvider/save', data })
}

export const delXbProvider = (data) => {
    return hunter_post_form({ path: '/xbProvider/del', data })
}

export const xbLoadPageList = (data) => {
    return hunter_post({ path: '/xbLoadPage/list', data })
}
export const xbLoadPageGet = (id) => {
    return hunter_post({ path: `/xbLoadPage/get?id=${id}`, data: {} })
}
export const xbLoadPageAdd = (data) => {
    return hunter_post({ path: '/xbLoadPage/add', data })
}
export const xbLoadPageMod = (data) => {
    return hunter_post({ path: '/xbLoadPage/mod', data })
}
export const xbLoadPageDel = (data) => {
    return hunter_post_form({ path: '/xbLoadPage/del', data })
}

// 发布
export const xbLoadPagePublish = (data) => {
    return hunter_post_form({ path: '/xbLoadPage/publish', data })
}

export const getViewSummary = (data) => {
    return hunter_post({ path: '/xbTrackFormRecord/viewSummary', data })
}

export const xbTrackFormRecordCommit = (data) => {
    return hunter_post({ path: '/xbTrackFormRecord/commit', data })
}

export const batchUploadxbTrackForm = (data) => {
    return hunter_post({
        path: '/xbTrackFormRecord/commit',
        data,
        loading: { status: false },
        showError: false,
        isCatch: true,
    })
}
export const batchUploadDyForm = (data) => {
    return hunter_post({
        path: '/xbTrackFormRecord/doudian/upload',
        data,
        loading: { status: false },
        showError: false,
        isCatch: true,
    })
}

export const xbTplComponentGet = (id) => {
    return hunter_post({ path: `/xbTplComponent/get?id=${id}`, data: {} })
}
export const xbTplComponentList = (data) => {
    return hunter_post({ path: '/xbTplComponent/list', data })
}

export const xbTplPageList = (data) => {
    return hunter_post({ path: '/xbTplPage/list', data })
}

export const getQrcodeUrl = (data) => {
    return cupid_post_form({ path: '/qrcode/gen', data })
}

export const merchantOptQrcodeHistoryList = (data) => {
    return hunter_post({ path: '/merchantOptQrcodeHistory/list', data })
}

export const merchantOptQrcodeMod = (data) => {
    return hunter_post({ path: '/merchantOptQrcode/mod', data })
}
export const merchantOptQrcodeList = (data) => {
    return hunter_post({ path: '/merchantOptQrcode/list', data })
}
export const merchantOptQrcodeAdd = (data) => {
    return hunter_post({ path: '/merchantOptQrcode/add', data })
}
export const merchantOptQrcodeDel = (data) => {
    return hunter_post_form({ path: '/merchantOptQrcode/del', data })
}

export const merchantOptQrcodeDetailMod = (data) => {
    return hunter_post({ path: '/merchantOptQrcodeDetail/mod', data, isCatch: true })
}
export const merchantOptQrcodeDetailList = (data) => {
    return hunter_post({ path: '/merchantOptQrcodeDetail/list', data })
}
export const merchantOptQrcodeDetailAdd = (data) => {
    return hunter_post({ path: '/merchantOptQrcodeDetail/add', data })
}

export const merchantOptQrcodeDetailSave = (data) => {
    return hunter_post({ path: '/merchantOptQrcodeDetail/save', data })
}

export const merchantOptQrcodeDetailDel = (data) => {
    return hunter_post_form({ path: '/merchantOptQrcodeDetail/del', data })
}

export const queryQrcodeSummary = (qrcodeIds) => {
    return hunter_post({ path: '/merchantOptQrcode/querySummary', data: { qrcodeIds } })
}

export const getProviderCategoryList = (data) => {
    return hunter_post({ path: '/xbProviderCategory/list', data })
}
export const modProviderCategory = (data) => {
    return hunter_post({ path: '/xbProviderCategory/mod', data })
}
export const addProviderCategory = (data) => {
    return hunter_post({ path: '/xbProviderCategory/add', data })
}
export const delProviderCategory = (data) => {
    return hunter_post_form({ path: '/xbProviderCategory/del', data })
}
export const getProviderPlatforms = (data) => {
    return hunter_post_form({ path: '/xbProvider/platforms', data })
}

export const getFormExpressList = (data) => {
    return hunter_post({ path: '/xbTrackFormRecord/express/list', data })
}

export const saveFormExpress = (data) => {
    return hunter_post({ path: '/xbTrackFormRecord/express/save', data })
}

export const uploadFormExpress = (data) => {
    return hunter_post({ path: '/xbTrackFormRecord/express/save', data, loading: { status: false } })
}

export const delFormExpress = (data) => {
    return hunter_post({ path: '/xbTrackFormRecord/express/del', data })
}

export const getProviderCategoryData = async () => {
    const res = await Promise.all([xbProviderList({ orderBy: '-id' }), getProviderCategoryList({})])
    const providerList = res[0].data
    const _map = {}
    res[0].data.forEach((item) => {
        _map[item.providerNo] = item.name
    })

    // 设置合并数据
    const list = []
    const _categoryMap = {}

    res[1].data.forEach((item) => {
        _categoryMap[item.id] = item
        _categoryMap[item.categoryNo] = item
        const n = { ...item, label: item.name, value: item.categoryNo, children: [] }
        res[0].data.forEach((p) => {
            if (n.categoryNo === p.categoryNo) {
                n.children.push({
                    ...p,
                    label: p.name,
                    value: p.providerNo,
                })
            }
        })
        list.push(n)
    })
    list.sort((a, b) => a.seq - b.seq)
    return {
        providerList,
        providerMap: _map,
        providerCascader: list,
        categoryList: res[1].data,
        categoryMap: _categoryMap,
    }
}

export const getShopList = (data) => {
    return hunter_post_form({ path: '/shop/list', data })
}

export const getShopProductList = (data) => {
    return hunter_post_form({ path: '/shop/product/detail', data })
}
export const bindShopProduct = (data) => {
    return hunter_post({ path: '/shop/product/bind', data })
}
export const unbindShopProduct = (data) => {
    return hunter_post_form({ path: '/shop/product/unbind', data })
}
export const getBindProductList = (data) => {
    return hunter_post_form({ path: '/shop/product/query', data })
}
