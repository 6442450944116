<template>
    <div class="user_wechat">
        <div class="title">微信</div>
        <div class="wechat_list">
            <div v-for="item in wechatList" :key="item.id">
                <el-descriptions size="mini" :colon="false" :column="2">
                    <el-descriptions-item label="微信昵称">{{ item.stuNickname }}</el-descriptions-item>
                    <el-descriptions-item label="操作人">{{ item.staffName }}</el-descriptions-item>
                    <el-descriptions-item label="状态">
                        <el-tag
                            effect="dark"
                            size="mini"
                            v-if="wechatStatusMap[item.state]"
                            :type="wechatStatusMap[item.state].tag"
                        >
                            {{ wechatStatusMap[item.state].name }}
                        </el-tag>
                    </el-descriptions-item>
                    <el-descriptions-item label="添加时间">
                        {{ item.applyTime ? item.applyTime.substr(0, 16) : '-' }}
                    </el-descriptions-item>
                    <el-descriptions-item label="">
                        <el-link v-if="editState" @click="onShowEditWechat(item)" type="primary">编辑</el-link>
                    </el-descriptions-item>
                    <el-descriptions-item label="通过时间">
                        {{ item.receiveTime ? item.receiveTime.substr(0, 16) : '-' }}
                    </el-descriptions-item>
                </el-descriptions>
            </div>

            <el-button type="primary" :disabled="!editState" @click="onShowEditWechat(null)" plain size="mini">
                添加微信
            </el-button>
        </div>

        <EditWechat
            :activeItem="activeItem"
            :isShow.sync="isShowEditWechat"
            :activeWechat="activeWechat"
            @onClose="onCloseEditWechat"
        />
    </div>
</template>
<script>
import { getStaffList } from '@/js/api/staffApi'
import { oppoWxFriendList } from '@/js/api/saleApi.js'
import { addWechatStatusMap } from '@/js/config/opportunit.js'
import EditWechat from './EditWechat.vue'

export default {
    props: ['activeItem', 'editState'],
    components: { EditWechat },
    data() {
        return {
            wechatStatusMap: addWechatStatusMap,

            wechatList: [],

            activeWechat: null,
            isShowEditWechat: false,
        }
    },
    created() {
        this.oppoWxFriendListApi()
    },
    methods: {
        onShowEditWechat(wechat) {
            this.activeWechat = wechat
            this.isShowEditWechat = true
        },
        onCloseEditWechat(status) {
            this.activeWechat = null
            this.isShowEditWechat = false
            if (status === true) {
                this.oppoWxFriendListApi()
                this.$emit('onUpdate')
            }
        },
        async oppoWxFriendListApi() {
            const res1 = await oppoWxFriendList({ projectNo: this.activeItem.projectNo, oppoId: this.activeItem.id })
            if (res1.data.length !== 0) {
                const _staffIds = []
                res1.data.forEach((i) => {
                    if (!_staffIds.includes(i.staffId)) {
                        _staffIds.push(i.staffId)
                    }
                })
                const res2 = await getStaffList({ staffIds: _staffIds })
                const _map = {}
                res2.data.forEach((staff) => {
                    _map[staff.id] = staff
                })
                res1.data.forEach((i) => {
                    i.staffName = _map[i.staffId] ? _map[i.staffId]['name'] : '-'
                })
            }
            this.wechatList = res1.data
        },
    },
}
</script>
<style lang="less" scoped>
.user_wechat {
    margin: 0 24px;
    flex-grow: 1;
    overflow: hidden;
    max-width: 540px;
}
.wechat_list {
    margin-top: 12px;
    .el-descriptions {
        margin-bottom: 16px;
    }
    .opt_disabled {
        cursor: no-drop;
    }
}
</style>
