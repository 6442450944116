export const contactType = {
    tel: '手机',
    wx: '微信',
}

export const followRecordType = {
    wx: '微信',
    tel: '电话',
}

export const followRecordTypeList = [
    {
        val: 'tel',
        name: '电话',
    },
    {
        val: 'wx',
        name: '微信',
    },
]
export const contactTypeList = [
    {
        val: 'tel',
        name: '电话',
    },
    {
        val: 'wx',
        name: '微信号',
    },
]

export const addWechatStatusList = [
    {
        val: 'APPLY',
        name: '申请中',
        tag: 'info',
    },
    {
        val: 'REJECT',
        name: '已拒绝',
        tag: 'warning',
    },
    {
        val: 'FRIEND',
        name: '已通过',
        tag: 'success',
    },
    {
        val: 'DELETED',
        name: '已被删除',
        tag: 'danger',
    },
    {
        val: 'INVALID',
        name: '无效',
        tag: 'danger',
    },
]

export const addWechatStatusMap = {
    APPLY: {
        name: '申请中',
        tag: 'info',
    },
    REJECT: {
        name: '已拒绝',
        tag: 'warning',
    },
    FRIEND: {
        name: '已通过',
        tag: 'success',
    },
    DELETED: {
        name: '已被删除',
        tag: 'danger',
    },
    INVALID: {
        name: '无效',
        tag: 'danger',
    },
    NONE: {
        name: '未处理',
        tag: 'danger',
    },
}

export const callStatusType = {
    dealing: '正常通话',
    notDeal: '振铃未接听',
    leak: '系统禁止拨打',
    queueLeak: '排队放弃',
    blackList: '黑名单',
    limit: '并发限制',
}

export const callStatusList = [
    {
        val: 'dealing',
        name: '正常通话',
    },
    {
        val: 'notDeal',
        name: '振铃未接听',
    },
    {
        val: 'leak',
        name: '系统禁止拨打',
    },
    {
        val: 'queueLeak',
        name: '排队放弃',
    },
    {
        val: 'blackList',
        name: '黑名单',
    },
    {
        val: 'limit',
        name: '并发限制',
    },
]
