<template>
    <div class="user_tel">
        <div class="title">联系方式</div>
        <div class="contact_list">
            <div class="contact_item" v-for="item in contactList" :key="`contact${item.id}`">
                <div class="contact_container">
                    <el-input size="mini" :value="item.contact" :disabled="true" />
                    <el-popconfirm @confirm="onDelContact(item)" title="确定删除吗？" v-if="item.optId !== 0">
                        <el-button size="mini" plain type="danger" slot="reference">删除</el-button>
                    </el-popconfirm>
                </div>
            </div>
            <div class="contact_opt">
                <el-button type="primary" size="mini" @click="onAddContact" plain>添加手机号</el-button>
            </div>
        </div>
    </div>
</template>
<script>
import { oppoContactList, oppoContactAdd, oppoContactDel } from '@/js/api/saleApi.js'
import { mapGetters } from 'vuex'

export default {
    props: ['activeItem', 'editState'],
    computed: { ...mapGetters(['userInfo']) },
    data() {
        return {
            contactList: [],
        }
    },
    created() {
        this.oppoContactListApi()
    },
    methods: {
        onDelContact(item) {
            oppoContactDel(item.id).then(() => {
                this.oppoContactListApi()
                this.$emit('onUpdate')
            })
        },
        onAddContact() {
            this.$prompt('请输入手机号', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputPattern: /^1\d{10}$/,
                inputErrorMessage: '手机号格式错误',
            })
                .then(({ value }) => {
                    oppoContactAdd({
                        oppoId: this.activeItem.id,
                        contact: value,
                        contactType: 'tel',
                        optId: this.userInfo.id,
                        staffId: this.userInfo.id,
                    }).then(() => {
                        this.$message.success('添加成功')
                        this.oppoContactListApi()
                        this.$emit('onUpdate')
                    })
                })
                .catch(() => {})
        },
        // 获取联系方式
        oppoContactListApi() {
            oppoContactList({
                oppoId: this.activeItem.id,
                projectNo: this.activeItem.projectNo,
            }).then((res) => {
                this.contactList = res.data
            })
        },
    },
}
</script>
<style lang="less" scoped>
.user_tel {
    width: 260px;
    flex-shrink: 0;
    box-sizing: border-box;
    .contact_list {
        margin-top: 12px;
    }
    .contact_container {
        display: flex;
        align-items: center;
        margin-bottom: 6px;
        .el-button {
            margin-left: 6px;
        }
    }
}
.contact_opt {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}
</style>
