<template>
    <el-dialog
        title="用户微信"
        :visible.sync="isShow"
        width="400px"
        :append-to-body="true"
        :before-close="onClose"
        @open="onOpen"
    >
        <el-form v-if="formData" :model="formData" :rules="rules" ref="formData" label-width="100px">
            <el-form-item label="运营企微" prop="staffWxid">
                <el-select @change="onStaffWxidChange" v-model="formData.staffWxid" placeholder="请选择">
                    <el-option
                        v-for="item in workWxList"
                        :key="item.cid"
                        :label="`${item.name}-${item.cid}`"
                        :value="item.cid"
                    />
                </el-select>
            </el-form-item>
            <el-form-item label="添加状态" prop="state">
                <el-select v-model="formData.state" placeholder="请选择" @change="onStateChange">
                    <el-option
                        v-for="item in addWechatStatusList"
                        :key="item.val"
                        :label="item.name"
                        :value="item.val"
                    />
                </el-select>
            </el-form-item>
            <el-form-item label="微信昵称" prop="stuNickname">
                <el-input placeholder="请输入微信昵称" :maxlength="32" show-word-limit v-model="formData.stuNickname" />
            </el-form-item>
            <el-form-item label="申请时间" prop="applyTime">
                <el-date-picker
                    :disabled="true"
                    v-model="formData.applyTime"
                    type="datetime"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    placeholder="请选择日期"
                    :picker-options="pickerOptions"
                />
            </el-form-item>
            <el-form-item v-if="formData.state === 'FRIEND'" label="通过时间" prop="receiveTime">
                <el-date-picker
                    :disabled="true"
                    v-model="formData.receiveTime"
                    type="datetime"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    placeholder="请选择日期"
                    :picker-options="pickerOptions"
                />
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="onClose">取 消</el-button>
            <el-button type="primary" @click="onSubmit">确 定</el-button>
        </span>
    </el-dialog>
</template>
<script>
import { queryStaffWxcp } from '@/js/api/staffApi.js'
import { oppoWxFriendAdd, oppoWxFriendMod, oppoList } from '@/js/api/saleApi.js'
import { addWechatStatusList } from '@/js/config/opportunit.js'
import { mapGetters } from 'vuex'
import { filterQueryEmpty } from '@/js/utils.js'
import dayjs from 'dayjs'

export default {
    props: ['isShow', 'activeItem', 'activeWechat'],
    computed: { ...mapGetters(['userInfo']) },
    data() {
        return {
            workWxList: [],
            staffWxList: [],
            addWechatStatusList,
            formData: null,
            lastAssignTime: '',
            rules: {
                staffWxid: [{ required: true, message: '请选择加微微信', trigger: 'change' }],
                stuNickname: [{ required: true, message: '请输入微信昵称', trigger: 'change' }],
                state: [{ required: true, message: '请选择', trigger: 'change' }],
            },
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now()
                },
            },
        }
    },
    methods: {
        onOpen() {
            let formData = {
                stuNickname: '',
                applyTime: dayjs().format('YYYY-MM-DD HH:mm:ss'),
                receiveTime: '',
                state: '',
                staffWxid: '',
            }
            this.lastAssignTime = ''
            if (this.activeWechat) {
                formData = { ...formData, ...this.activeWechat }
            }
            oppoList({ projectNo: this.activeItem.projectNo, oppoId: this.activeItem.id }).then((res) => {
                this.lastAssignTime = res.data[0]['lastAssignTime']
                this.formData = formData
                this.onStateChange()
                this.queryStaffWxcpApi()
            })
        },
        onClose() {
            this.formData = null
            this.$emit('onClose')
        },

        onStateChange() {
            if (this.formData.state === 'INVALID') {
                this.rules.stuNickname[0]['required'] = false
            } else {
                this.rules.stuNickname[0]['required'] = true
            }
            if (this.formData.state === 'FRIEND' && !this.formData.receiveTime) {
                this.formData.receiveTime = dayjs().format('YYYY-MM-DD HH:mm:ss')
            }
        },

        onStaffWxidChange() {
            if (this.formData.staffWxid) {
                this.$store.dispatch('user/storage', { key: 'edit_wechat_cid', val: this.formData.staffWxid })
            }
        },

        onSubmit() {
            this.$refs.formData.validate((valid) => {
                if (valid) {
                    const data = filterQueryEmpty({ ...this.formData })
                    this.workWxList.forEach((i) => {
                        if (data.staffWxid === i.cid) {
                            data.appId = i.corpId
                        }
                    })
                    if (data.id) {
                        oppoWxFriendMod({ ...data }).then(() => {
                            this.$message.success('修改成功')
                            this.$emit('onClose', true)
                        })
                    } else {
                        oppoWxFriendAdd({
                            projectNo: this.activeItem.projectNo,
                            staffId: this.userInfo.id,
                            oppoId: this.activeItem.id,
                            ...data,
                        }).then(() => {
                            this.$message.success('添加成功')
                            this.$emit('onClose', true)
                        })
                    }
                }
            })
        },
        queryStaffWxcpApi() {
            queryStaffWxcp({ staffId: this.userInfo.id }).then((res) => {
                this.workWxList = res.data
                this.$store.dispatch('user/storage', { key: 'edit_wechat_cid' }).then((res) => {
                    this.workWxList.forEach((i) => {
                        if (res === i.cid) {
                            this.formData.staffWxid = i.cid
                        }
                    })
                })
            })
        },
    },
}
</script>
<style lang="less" scoped>
.el-date-editor,
.el-select {
    width: 100%;
}
</style>
